<template>
    <div>
        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            @created="created"
            @updated="updated"
            @cancelled="cancelled"
            @deleted="deleted"
        >
            <form-panel>
                <form-two-col-row>
                    <template v-slot:col1>
                        <h3 class="headline pt-3 pb-6 black--text">
                            {{ $t('labels.team_settings_title') }}
                        </h3>

                        <text-input
                            v-model="form.name"
                            v-validate="'required|max:255|space'"
                            name="name"
                            label="labels.name"
                            :error-messages="errors.collect('name')"
                            :data-vv-as="$t('labels.name')"
                        ></text-input>
                    </template>
                    <template v-slot:col2></template>
                </form-two-col-row>
                <form-two-col-row>
                    <template v-slot:col1>
                        <!-- team members -->
                        <entity-select-input
                            v-model="form.employees"
                            v-validate="''"
                            label="labels.company_team_members"
                            :data-vv-as="$t('labels.company_team_members')"
                            :entity="selectApiClientType.USER"
                            :query-params="{
                                company: `/api/v1/companies/${companyId}`,
                            }"
                            name="companyTeamMembers"
                            autocomplete
                            :error-messages="
                                errors.collect('companyTeamMembers')
                            "
                            multi-select
                        ></entity-select-input>
                    </template>
                </form-two-col-row>
            </form-panel>
        </base-form>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import ApiClientType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import RestApiCollection from '@/api/RestApiCollection'
import FormBase from '@/components/mixins/FormBase'
import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        TextInput,
        EntitySelectInput,
    },
    mixins: [FormBase],
    props: {
        companyId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            api: ApiClientType.COMPANY_TEAMS,
            mapper: MapperType.COMPANY_TEAMS_FORM,
            form: {
                name: null,
                employees: [],
                company: `/api/v1/companies/${this.companyId}`,
            },
            entity: 'labels.company_teams',
            editRoute: routeType.COMPANY_TEAM_EDIT,
            listRoute: routeType.COMPANY_TEAMS_LIST,
            companyApiClient: RestApiCollection.get(
                ApiClientType.COMPANY_TEAMS
            ),
        }
    },
    methods: {
        editRedirectParams(data) {
            return {
                teamId: data.id,
                companyId: this.companyId,
            }
        },
        listRedirectParams() {
            return { companyId: this.companyId }
        },
    },
}
</script>
