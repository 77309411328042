<template>
    <div>
        <page-title>{{ title }}</page-title>
        <company-route-tabs
            v-if="isSuperAdmin"
            :company-id="companyId"
        ></company-route-tabs>
        <company-teams-table :company-id="companyId">
            <template v-slot:actions>
                <create-button
                    :route-name="routeType.COMPANY_TEAM_CREATE"
                ></create-button>
            </template>
        </company-teams-table>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import SelectApiClienType from '@/api/SelectApiClientType'
import TitleMixinGenerator from '@/components/mixins/page-title/TitleMixinGenerator'
import CreateButton from '@/components/form/Actions/CreateButton'
import CompanyRouteTabs from '@/components/pages/companies/RouteTabs'
import CompanyTeamsTable from '@/components/pages/companyTeams/Table'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: {
        CreateButton,
        CompanyRouteTabs,
        CompanyTeamsTable,
    },
    mixins: [
        TitleMixinGenerator(SelectApiClienType.COMPANY, 'companyId'),
        AuthMixin,
    ],
    props: {
        companyId: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            routeType,
        }
    },
}
</script>
