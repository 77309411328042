<template>
    <div>
        <company-route-tabs
            v-if="isSuperAdmin"
            :company-id="companyId"
        ></company-route-tabs>
        <company-teams-form :company-id="companyId"></company-teams-form>
    </div>
</template>

<script>
import CompanyTeamsForm from '@/components/pages/companyTeams/Form'
import CompanyRouteTabs from '@/components/pages/companies/RouteTabs'
import dirtyForm from '@/components/mixins/dirtyForm'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: { CompanyTeamsForm, CompanyRouteTabs },
    mixins: [dirtyForm, AuthMixin],

    props: {
        companyId: {
            type: [String, Number],
            default: null,
        },
    },
}
</script>
