<template>
    <data-iterator
        ref="iterator"
        :api="api"
        :filters.sync="filters"
        :fixed-filters="{ company: company }"
    >
        <template v-slot:actions>
            <slot name="actions"></slot>
        </template>

        <template slot-scope="{ items }">
            <d-table
                :items="items"
                :headers="headers"
                :filters.sync="filters"
                row-action
                @onRowSelected="onRowSelected"
            >
                <!-- name column -->
                <template v-slot:[`item.name`]="{ item }">
                    {{ item.name }}
                </template>

                <!-- row actions -->
                <template v-slot:rowActions="{ item }">
                    <edit-button :to="getEditLink(item)"></edit-button>
                    <delete-action
                        :entity-name="item.name"
                        :entity-id="item.id"
                        :api="api"
                        @deleted="onEntityDeleted"
                        @failed="onEntityDeleteFailed"
                    ></delete-action>
                </template>
            </d-table>
        </template>
    </data-iterator>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import RestApiCollection from '@/api/RestApiCollection'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import TeamsHeaders from '@/pages/companies/mixins/TeamsHeaders'

export default {
    mixins: [DataIteratorMixin, DataTableMixin, TeamsHeaders],
    props: {
        companyId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            api: RestApiType.COMPANY_TEAMS,
            dialog: false,
            selectedItem: null,
            filters: {},
        }
    },
    computed: {
        company() {
            return RestApiCollection.get(RestApiType.COMPANIES).getItemIri(
                this.companyId
            )
        },
    },
    methods: {
        getEditLink(item) {
            return {
                name: routeType.COMPANY_TEAM_EDIT,
                params: { companyId: this.companyId, teamId: item.id },
            }
        },
    },
}
</script>
